import React, {useEffect, useLayoutEffect, useState} from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import School from "./School/School";
import Headers from "./Header/Header";

import Footer from "./Footer/Footer";
import SinglePage from "./SinglePage/SinglePage";
import SchoolMap from "./SchoolsMap/SchooolMap";
import MajorDetails from "./MajorDetails/MajorDetails";
import MajorDetailsSingle from "./MajorDetailsSingle/MajorDetailsSingle";
import Tvet from "./Tvet/Tvet";
import i18n from "../i18n";
import {initGA, logPageView} from "../utils/ga";
function PageSwitch() {
  const location = useLocation();
  const [key, setKey] = useState(location.pathname);

  useLayoutEffect(() => {
    setKey(location.pathname);
    if (localStorage.getItem('lang') === "ar") {
      i18n.changeLanguage("ar");
      document.body.dir = "rtl";
      document.documentElement.setAttribute("lang", "ar");
    } else {
      i18n.changeLanguage("en");
      document.body.dir = "ltr";
      document.documentElement.setAttribute("lang", "en");

    }
  }, [location.pathname]);

  useEffect(() => {
    const hostname = window.location.hostname;
    initGA(hostname);
  }, []);

  useEffect(() => {
    logPageView();
  }, []);

  return (
    <>
      <Headers />
      <Routes>
        <Route path="/" element={<School />} />
        <Route path="/singlepag/:id" element={<SinglePage />} />
        <Route path="/schoolsmap" element={<SchoolMap />} />
        <Route path="/tvet" element={<Tvet />} />
        <Route path="/majordetails" element={<MajorDetails />} />
        <Route path="/majordetailsingle/:id" element={<MajorDetailsSingle />} />
      </Routes>
      <Footer />
    </>
  );
}
export default PageSwitch;
