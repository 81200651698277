// src/analytics.js
import ReactGA from 'react-ga4';

// Define measurement IDs for each domain
const measurementIds = {
    'schools.mehnati.org': 'G-J75V3CS511', // Replace with actual ID
};

export const initGA = (hostname) => {
    const trackingID = measurementIds[hostname];

    if (trackingID) {
        ReactGA.initialize(trackingID);
        console.log(`Initialized GA for ${hostname} with ID ${trackingID}`);
        // Correctly configure GA
        window.gtag('config', trackingID, { 'anonymize_ip': true });
    } else {
        console.error(`No GA tracking ID found for ${hostname}`);
    }
};
export const logPageView = () => {
    window.gtag('event', 'page_view', {
        page_path: window.location.pathname + window.location.search,
    });
};

